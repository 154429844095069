<template>
    <div class="h-screen overflow-hidden flex items-center">
        <section class="w-[85vw] mx-auto" :class="(gameDone ? 'h-screen' : '')">
            <div v-if="!gameDone" class="pb-12">
                <div :class="landscape ? 'flex' : ''" id="section-content">
                    <div class="tablet:w-1/2 tablet:flex tablet:justify-center tablet:flex-col">
                        <p class="text-white text-center mb-12 max-w-[60%] mx-auto">{{$t('sound.description')}}</p>
                        <div :class="(landscape ? 'grid grid-cols-2 w-1/2 mx-auto gap-6 h-full' : 'justify-between flex')">
                            <div v-for="(post, index) of posts" :key="'img_' + post.id" >
                                <div class="h-24 w-24 2xl:w-80 2xl:h-80 flex justify-center rounded-full border-2 border-dashed border-white relative mx-auto">
                                    <img :src="post.img" :id="'draggable_' + post.id + '_' + index" :ref="'draggable_' + post.id" :class="(post.found ? 'hidden overflow-hidden' : '') + 'overflow-hidden object-cover object-center max-w-none w-auto h-full rounded-full drag-img z-20 scale-105'" data-x="0" data-y="0"/>
                                </div>
                                <p v-if="lang == 'hu' && post.hu_name" class="text-halfColonialWhite text-bodyM text-center pt-5">{{post.hu_name}}</p>
                                <p v-else-if="lang == 'en' && post.en_name" class="text-halfColonialWhite text-bodyM text-center pt-5">{{post.en_name}}</p>
                                <p v-else-if="lang == 'de' && post.de_name" class="text-halfColonialWhite text-bodyM text-center pt-5">{{post.de_name}}</p>
                                <p v-else class="text-halfColonialWhite text-bodyM text-center pt-5">{{post.title}}</p>
                            </div>
                        </div>
                    </div>
                    <div :class="(landscape ? '' : 'pt-20')" class="">
                        <ul class="gap-x-1 justify-items-center 2xl:scale-birdSound 2xl:translate-x-1/2 2xl:translate-y-1/2" id="sounds-block">
                            <li v-for="index of randomIndex" :key="'sound_' + posts[index].id" :id="'sound_' + posts[index].id" :class="(posts[index].found ? 'soundCorrect' : '')" class="border-solid border-2 my-6 tablet:my-1 px-2 h-24 flex justify-between items-center rounded-birdSound border-birdSound relative z-10">
                                <div :class="(posts[index].found ? '' : 'hidden')" class="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-success-dot rounded-birdSound px-3 py-1">
                                    <p v-if="lang == 'hu' && posts[index].hu_name" class="text-bodyS text-halfColonialWhite">{{ posts[index].hu_name }}</p>
                                    <p v-else-if="lang == 'en' && posts[index].en_name" class="text-bodyS text-halfColonialWhite">{{ posts[index].en_name }}</p>
                                    <p v-else-if="lang == 'de' && posts[index].de_name" class="text-bodyS text-halfColonialWhite">{{ posts[index].de_name }}</p>
                                    <p v-else class="text-bodyS text-halfColonialWhite">{{ posts[index].title }}</p>
                                </div>
                                <div v-if="!posts[index].found" class="border-solid h-20 w-20 rounded-full flex bg-redDamask-opacity20">
                                    <img :class="(posts[index].pauseable || posts[index].played ? 'hidden' : '') + ' mx-auto my-auto cursor-pointer w-8 h-8'" :src="src('play')" @click="play(posts[index])" >
                                    <img :class="(posts[index].pauseable ? '' : 'hidden') + ' mx-auto my-auto cursor-pointer w-8 h-8'" :src="src('pause')" @click="pause(posts[index])" >
                                    <img :class="(posts[index].played ? '' : 'hidden') + ' mx-auto my-auto cursor-pointer'" :src="src('replay')" @click="play(posts[index])" >
                                </div>
                                <div v-else class="border-solid h-20 w-20 rounded-full flex bg-success-birdSound">
                                    <img :class="(posts[index].pauseable || posts[index].played ? 'hidden' : '') + ' mx-auto my-auto cursor-pointer w-8 h-8'" :src="src('playCorrect')" @click="play(posts[index])" >
                                    <img :class="(posts[index].pauseable ? '' : 'hidden') + ' mx-auto my-auto cursor-pointer w-8 h-8'" :src="src('pauseCorrect')" @click="pause(posts[index])" >
                                    <img :class="(posts[index].played ? '' : 'hidden') + ' mx-auto my-auto cursor-pointer'" :src="src('replayCorrect')" @click="play(posts[index])" >
                                </div>
                                <div class="px-2" style="max-width: 72%;">
                                    <img :class="(posts[index].playing || posts[index].played ? 'hidden' : posts[index].found ? 'hidden' : '') + ' mx-auto my-auto cursor-pointer'" :src="src('soundNotPlayed')">
                                    <img :class="(!posts[index].playing || posts[index].played ? 'hidden' : posts[index].found ? 'hidden' : '') + ' mx-auto my-auto cursor-pointer'" :src="src('soundPlaying')">
                                    <img :class="(posts[index].played ? posts[index].found ? 'hidden' : '' : 'hidden') + ' mx-auto my-auto cursor-pointer'" :src="src('soundPlayed')">
                                    <img :class="(posts[index].found ? '' : 'hidden') + ' mx-auto my-auto cursor-pointer'" :src="src('soundCorrect')">
                                
                                </div>
                                <div class="h-20 w-20 flex rounded-full dashed-border">
                                    <img :src="posts[index].img" :class="(posts[index].found ? '' : 'hidden') + ' w-20 h-20 rounded-full border-2 border-solid border-success-dot'"/>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-else :class="(landscape ? 'successLandscape' : 'successPortrait')" class="successPage">
                <div class="2xl:h-[85vh]">
                    <img :class="(landscape ? 'hidden' : '') + ' mx-auto my-auto cursor-pointer'" src="../assets/soundStart.png">
                    <img :class="(!landscape ? 'hidden' : '') + ' mx-auto my-auto cursor-pointer h-[30%]'" src="../assets/soundStartLandscape.png">
                    <div :class="(landscape ? 'h-1/2' : 'h-[40%]') + ' relative mx-auto mb-8'">
                        <img class="h-full mx-auto my-auto cursor-pointer" src="../assets/birdBackground.png">
                        <img :class="(landscape ? 'w-[22%]' : 'w-[35%]') + ' absolute bottom-0 left-1/2 -translate-x-bird-start'" src="../assets/bird.png">
                    </div>
                    <div class="mx-auto pb-6 max-w-[71vw]">
                        <p class="font-heading text-redDamask  text-center text-h1">{{$t('sound.gratulation')}}</p>
                        <!-- <p class="font-heading text-white  text-center text-h3">{{$t('sound.gratulationDescription')}}</p> -->
                    </div>
                </div>
            </div>
            <div :class="'flex ' + (gameDone ? 'justify-center' : 'justify-between')">
                <router-link :to="{ name: 'BirdSoundStart' }">
                    <Button :text="$t('sound.back')" buttonType="tertiary"/>
                </router-link>
                <div class="flex">
                    <router-link :to="{ name: 'BirdSoundTutorial' }">
                        <Button v-if="!gameDone" :text="$t('sound.help')" buttonType="secondary"/>
                    </router-link>
                    <NextSounds :pagination="pagination" @next="postsData.page++; getPosts();" class="pl-4"/>
                </div>
            </div>
            
        </section>
    </div>
</template>

<script>

import Button from '@/components/Button.vue';
import NextSounds from '@/components/NextSounds';
import interact from "interactjs";

export default {
    name: "Tablet",
    components: {
        Button,
        NextSounds,
    },
    mounted() {
        this.$i18n.locale = this.lang ? this.lang : 'ro';
        this.getPosts();
        this.myEventHandler();
    },
    data(){
        return {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
            posts: [],
            randomIndex: [],
            foundedPosts: {},
            startedSounds: {},
            baseURL: process.env.VUE_APP_AXIOS_URL,
            postsData: {
                per_page: 6,
                page: 1,
                lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
            },
            pagination: {
                prevPage: '',
                nextPage: '',
                totalPages: '',
                from: '',
                to: '',
                total: '',
            },
            logo: '',
            windowWidth: window.innerWidth,
			windowHeight: window.innerHeight,
            player: new Audio(),
            current: {},
            coverObject: { cover: true, animated: false },
            index: 0,
            isPlaying: false,
            currentlyTimer: 0,
            dragg: false,
            gameDone: true,
            landscape: false,
        }
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    unmounted() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    methods: {
        getPosts() {
            this.dragg = false;
            this.gameDone = false;
            this.axios.get(this.baseURL + `/wp-json/wp/v2/bird_sounds`, {params: this.postsData}, {
                headers: {
                        'Access-Control-Allow-Origin': '*'
                }
            }).then(response => {
                this.posts = response.data;
                this.configPagination(response.headers);
                this.randomIndex = this.randoming(Array.from(Array(this.posts.length).keys()), this.posts.length);
            }).catch( (error) => {
                // console.log(error);
            });
            
        },
        configPagination(headers) {
            this.pagination.total = +headers['x-wp-total'];
            this.pagination.totalPages = +headers['x-wp-totalpages'];
            this.pagination.from = this.pagination.total ? ((this.postsData.page - 1) * this.postsData.per_page) + 1 : ' ';
            this.pagination.to = (this.postsData.page * this.postsData.per_page) > this.pagination.total ? this.pagination.total : this.postsData.page * this.postsData.per_page;
            this.pagination.prevPage = this.postsData.page > 1 ? this.postsData.page : '';
            this.pagination.nextPage = this.postsData.page < this.pagination.totalPages ? this.postsData.page + 1 : '';
            if (this.pagination.total < this.pagination.to + 6) {
                this.pagination.nextPage = "";
            }
        },
        langchanged_(value) {
            this.postsData.page = 1;
            this.postsData.lang = value;
            this.getPosts();
        },
        src(name) {
            return require("../assets/icons/" + name + ".svg")
        },
        listenersWhenPlay(song) {
            this.player.addEventListener("timeupdate", () => {
                var playerTimer = this.player.currentTime;
                this.currentlyTimer = playerTimer;
                this.current.percent = (playerTimer * 100) / this.current.seconds;
                this.current.isPlaying = true;
            });
            this.player.addEventListener(
                "ended",
                function() {
                    this.current.played = true;
                    this.current.pauseable = false;
                    this.current.playing = false;
                    // this.next();
                }.bind(this)
            );
            this.player.onloadedmetadata = () => {
                this.current.seconds = this.player.duration
                this.startedSounds[this.current.id] = true;
            }
        },
        play(song) {
            if (!this.dragg) {
                this.dragg = true;
                this.initDragg();
            }
            if (typeof song.sound !== "undefined" && !(song.id == this.current.id && this.player.paused)) {
                this.current.isPlaying = false;
                this.current.pauseable = false;
                this.current.playing = false;
                this.current = song;
                this.player.src = this.current.sound;
            }
            song.pauseable = true;
            song.playing = true;
            song.played = false;
            this.player.play();
            this.isPlaying = true;
            this.listenersWhenPlay(song);
        },
        pause(song) {
            song.played = false;
            song.pauseable = false;
            this.player.pause();
            this.isPlaying = false;
        },
        startDrag(evt, item) {
            evt.dataTransfer.dropEffect = 'move'
            evt.dataTransfer.effectAllowed = 'move'
            evt.dataTransfer.setData('itemID', item.id)
        },
        onDrop(evt, list) {
            const itemID = evt.dataTransfer.getData('itemID')
            const item = this.items.find((item) => item.id == itemID)
            item.list = list
        },
        initInteract: function (selector) {
            interact(selector).draggable({
                // enable inertial throwing
                inertia: true,
                // keep the element within the area of it's parent
                restrict: {
                // restriction: "parent",
                    endOnly: true,
                    elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
                },
                // enable autoScroll
                autoScroll: true,

                // call this function on every dragmove event
                onmove: this.dragMoveListener,
                // call this function on every dragend event
                onend: this.onDragEnd,
            });
        },
        dragMoveListener: function (event) {
            var target = event.target,
                // keep the dragged position in the data-x/data-y attributes
                x =
                (parseFloat(target.getAttribute("data-x")) || 0) +
                event.dx,
                y =
                (parseFloat(target.getAttribute("data-y")) || 0) +
                event.dy;

            target.style.webkitTransform = target.style.transform =
                "translate(" + x + "px, " + y + "px)";

            // update the posiion attributes
            target.setAttribute("data-x", x);
            target.setAttribute("data-y", y);
        },
        onDragEnd: function (event) {
            let list = document.getElementById('sounds-block');
            let listRect = list.getBoundingClientRect();
            let imageRect = event.target.getBoundingClientRect();
            let goBack = true;
            if (((imageRect.left + imageRect.right) / 2 >= listRect.left && (imageRect.left + imageRect.right) / 2 <= listRect.right) &&
                ((imageRect.top + imageRect.bottom) / 2 >= (listRect.top - 12) && (imageRect.top + imageRect.bottom) / 2 <= (listRect.bottom + 12))) {
                let percent = (((imageRect.top + imageRect.bottom) / 2) - listRect.top + 12) / (listRect.height + 24);
                let index = 0;
                for (let i = this.postsData.per_page; i > 0; i--) {
                    if (i/this.postsData.per_page >= percent) {
                        index = i - 1;
                    } 
                    else { 
                        break;
                    }
                }
                if (!this.startedSounds[list.children[index].id.split('_')[1]]) {
                    // console.log('not started');
                } else {
                    if (this.foundedPosts[list.children[index].id.split('_')[1]]) {
                        // console.log('already founded');
                    } else {
                        if (list.children[index].id.split('_')[1] == event.target.id.split('_')[1]) {
                            // console.log('equal');
                            this.posts[event.target.id.split('_')[2]].isPlaying ? this.pause(this.posts[event.target.id.split('_')[2]]) : '';
                            this.posts[event.target.id.split('_')[2]].found = true;
                            this.foundedPosts[event.target.id.split('_')[1]] = true;
                            document.getElementById(list.children[index].id).style.backgroundColor = "rgba(105, 179, 76, 0.28)";
                            goBack = false;
                        } else {
                            // console.log('not equal');
                            document.getElementById(list.children[index].id).style.backgroundColor = "rgba(105, 0, 0, 0.28)";
                        }
                    }
                }
            }

            this.gameDone = false;
            if (goBack) {
                var target = event.target;
                target.style.webkitTransform = target.style.transform =
                    "translate(" + 0 + "px, " + 0 + "px)";
                target.setAttribute("data-x", 0);
                target.setAttribute("data-y", 0);
            } else {
                this.gameDone = true;
                for (const index in this.posts) {
                    if (!this.posts[index].found) {
                        this.gameDone = false;
                        break;
                    }
                }
            }
        },
        initDragg() {

            Object.keys(this.$refs).forEach(el => {
                if (this.$refs[el].length > 0) {
                    this.initInteract( this.$refs[el][0] );
                }
            })
        },
        randoming(arr, n) {
            var result = new Array(n),
                len = arr.length,
                taken = new Array(len);
            if (n > len)
                throw new RangeError("getRandom: more elements taken than available");
            while (n--) {
                var x = Math.floor(Math.random() * len);
                result[n] = arr[x in taken ? taken[x] : x];
                taken[x] = --len in taken ? taken[len] : len;
            }
            return result;
        },
        myEventHandler() {
            screen.width > screen.height ? this.landscape = true : this.landscape = false;
        },
    },
};

</script>

<style>
    .dashed-border {
        background: rgba(218, 114, 60, 0.2);
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='white' stroke-width='3' stroke-dasharray='9%2c 9' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 100px;
    }
    .dashed-border-bird {
        background: rgba(218, 114, 60, 0.2);
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='white' stroke-width='3' stroke-dasharray='8%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 100px;
    }
    .drag-img {
        touch-action: none;
    }
    .bird_brench {
        position: relative;
        width: 205px;
        margin-left: auto;
        margin-right: auto;
    }
    .bird_brench_img {
        width: 240px;
        position: absolute;
        bottom: 0;
    }

    .soundCorrect {
        border: 2px solid rgba(105, 179, 76, 0.2);
    }

    .successPortrait {
        margin-top: -25%;
    }

    .successLandscape {
        margin-top: -5%;
    }
</style>