<template>
    <Button v-if="pagination.nextPage" @click="$emit('next')" buttonType="primary" :text="$t('sound.nextGame')"/>
</template>

<script>
    import BaseIcon from '@/components/BaseIcon.vue';
    import Button from '@/components/Button.vue';

    export default {
        name: 'NextSounds',
        components: {
            Button,
        },
        props: ['pagination'],
        mounted() {
            this.$i18n.locale = this.lang;
        },
    }
</script>
