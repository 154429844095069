<template>
    <div class="h-screen overflow-hidden">
        <div class="h-full relative">
            <div class="absolute top-8 w-full flex justify-between px-8 z-90">
                <router-link :to="{ name: 'BirdSoundStart' }" class="flex items-center hover:bg-redDamask focus:bg-redDamask active:bg-redDamask hover:bg-opacity-20 focus:bg-opacity-20 active:bg-opacity-20 border border-transparent rounded-full max-w-max pr-4">
                    <BaseIcon name="arrowLeft" outline= false />
                    <span class="inline-block font-body text-bodyM dark:text-halfColonialWhite text-sepiaBlack my-auto">{{ $t('sound.backText') }}</span>
                </router-link>
                <div class="flex flex-row items-center">
                    <img :src="src()" alt="logo" class="w-[7vw] h-auto">
                    <p class="font-heading text-white text-[1.5vw]">Muzea</p>
                </div>
            </div>
            <div :class="(isTablet ? (landscape ? '-top-16 tabletTutorialImageBlock' : '-top-40') : '-top-44') + ' mx-auto mb-8 relative'" id="tutorialImageBlock">
                <div class="bird-overlay"></div>
                <img :class="(landscape ? 'max-h-birdTutorial' : 'max-h-[21vh]')"  class="mx-auto " src="../assets/soundStart.png">
                <div class="relative z-10">
                    <img :class="(landscape ? 'max-h-birdTutorial' : 'h-auto min-w-[35vw]')"  class="mx-auto" src="../assets/birdBackground.png">
                    <img :class="(landscape ? 'max-h-[21vh]' : 'max-h-[39vh]')" class="mx-auto absolute bottom-0 translate-x-bird-tutorial right-1/2" src="../assets/bird.png">
                </div>
                <div :class="(isTablet ? 'bottom-0' : 'bottom-[10%]')" class="textBox absolute left-1/2 -translate-x-1/2 text-center z-30 max-w-[30vw]">
                    <h1 :class="(isTablet ? 'text-h3' : 'text-h1')" class="text-white font-heading">{{$t('sound.title')}}</h1>
                    <h5 class="text-white text-bodyL">{{$t('sound.description')}}</h5>
                </div>
            </div>
            <div :class="(isTablet ? (landscape ? '-mt-32 tabletTutorialGrid min-h-[60%]' : 'overflow-auto -translate-y-1/4') : '-mt-24 min-h-[60%]')">
                <div :class="(landscape ? 'min-h-[50vh] max-w-[50vw] translate-x-[75%]' : 'translate-x-[10%] w-[120%]')" class="grid grid-cols-2 gap-10 mx-12 pt-16 pb-12"> 
                    <div class="help help-first active mx-4 p-8 bg-bggt text-white rounded-2xl" @click="helpCardChange('help-first'), layoutChange(1)">
                        <img class="w-full" src="../assets/soundTutorial1.png" />
                        <h4 class="text-redDamask pt-4 text-2xl">{{$t('scratch.step1')}}</h4>
                        <h3 :class="(isTablet ? 'pt-0' : '')" class="pt-4 text-4xl font-heading">{{$t('sound.tutorialTitle1')}}</h3>
                        <p class="pt-4 text-sm">{{$t('sound.tutorialText1')}}</p>
                    </div>
                    <div class="help help-second  mx-4 p-8 bg-bggt text-white rounded-2xl"  @click="helpCardChange('help-second'), layoutChange()">
                        <img class="w-full" src="../assets/soundTutorial2.png" />
                        <h4 class="text-redDamask pt-4 text-2xl">{{$t('scratch.step2')}}</h4>
                        <h3 :class="(isTablet ? 'pt-0' : '')" class="pt-4 text-4xl font-heading">{{$t('sound.tutorialTitle2')}}</h3>
                        <p class="pt-4 text-sm">{{$t('sound.tutorialText2')}}</p>
                    </div>
                    
                </div>
            </div>
            <div class="absolute bottom-10 z-40 flex w-full justify-center">
                <router-link :to="{ name: 'BirdSound' }" class="router-link">
                    <Button :text="$t('scratch.startGame')" buttonType="primary" @click="startGame" />
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>

import Header from "@/components/Header.vue";
import Button from "@/components/Button.vue";
import BaseIcon from '@/components/BaseIcon.vue';

export default {
	name: 'BirdSoundTutorial',
	components: {
		Header,
        Button,
        BaseIcon
	},
	data(){
        return {
            help: false,
            landscape: false,
            isTablet: false
        }
    },
    mounted() {
    this.myEventHandler();
    },
	created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    unmounted() {
        window.removeEventListener("resize", this.myEventHandler);
    },
	methods: {
        startTutorial() {
            this.help = true;
        },
        src() {
            return require("../assets/logo_dark.png")
        },
         myEventHandler() {
            screen.width > screen.height ? this.landscape = true : this.landscape = false;
            screen.width <= 2000 ? this.isTablet = true : this.isTablet = false;
        },
        helpCardChange(activateClass) {
            const helpSection =  this.$el.querySelectorAll('.help');
            for (let i = 0; i < helpSection.length; i++) {
                helpSection.item(i).classList.remove('active');
            }
            this.$el.querySelectorAll('.' + activateClass).item(0).classList.toggle('active');
        },
        layoutChange(num = 2) {
            if (this.landscape) {
                document.querySelector("#tutorialImageBlock").classList.add("stepTwo");
                document.querySelector(".tabletTutorialGrid").classList.add("stepTwo");
                this.layoutChanged = true;
            } else {
                if (num == 1) {
                    document.querySelector(".overflow-auto").classList.remove("stepTwoPortrait");
                } else {
                    document.querySelector(".overflow-auto").classList.add("stepTwoPortrait");
                }
            }
        }
    },
}
</script>

<style>
.help.active {
    width: 106%;
    margin: 0;
    height: 112%;
    background-color: #FDF1D6;
    color: #261710 !important;
    transform: translate(-4%, -6%) !important;
}

.bird-overlay {
    position: absolute;
    z-index: 20;
    height: 130%;
    width: 100%;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}

#tutorialImageBlock.stepTwo {
    transform: scale(3) translate(-10%, 15%);
}

#tutorialImageBlock.stepTwo.tabletTutorialImageBlock {
    transform: scale(2.7) translate(-11%, 25%);
}

.tabletTutorialGrid.stepTwo {
    margin-top: -25vh;
}

#tutorialImageBlock.stepTwo .bird-overlay {
    display: none;
}

#tutorialImageBlock.stepTwo .textBox {
    position: inherit;
    max-width: 10vw;
}

#tutorialImageBlock.stepTwo .textBox h1 {
    font-size: 14px;
    padding: 4px 0;
    line-height: 1;
}

#tutorialImageBlock.stepTwo.tabletTutorialImageBlock .textBox h5 {
    font-size: 6px;
    line-height: 1;
}

.overflow-auto.stepTwoPortrait .grid {
    transform: translate(-40%, 0);
}

</style>