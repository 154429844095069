<template>
<div class="max-w-screen-xl max-h-screen-tablet-cross-axis mx-auto py-2 overflow-x-hidden" v-on:click="click">
    <div v-if="seconds == 0" class=" sticky top-1/2 dark:sticy-header-background" style="z-index: 99;">
        <div class="text-center flex flex-col justify-center">
            <img :src="src()" alt="Logo" class="w-44 mx-auto">
            <h2 class="font-heading text-h2 text-redDamask dark:text-redDamask mt-10 mb-2">{{ $t('language.selectTitle') }}</h2>
            <p class="font-body text-bodyM text-sepiaBlack dark:text-halfColonialWhite">{{ $t('language.text') }}</p>
            <Language flag tablet :language="lang" class="mt-6"/>
            <Button xl :text="$t('language.saveBtn')" buttonType="primary" v-on:click="startTimer" fullWidth class="mt-24"/>
        </div>
    </div>
    <div v-else class="h-screen overflow-hidden">
        <div class="h-full">
            <div class="absolute top-8 right-9 flex flex-row items-center">
                <img :src="src()" alt="logo" class="w-[7vw] h-auto">
                <p class="font-heading text-white text-[1.5vw]">Muzea</p>
            </div>
            <img :class="(landscape ? 'hidden' : '') + ' mx-auto my-auto cursor-pointer'" src="../assets/soundStart.png">
            <img :class="(!landscape ? 'hidden' : '') + ' mx-auto my-auto cursor-pointer h-[30%]'" src="../assets/soundStartLandscape.png">
            <div :class="(landscape ? 'h-1/2' : 'h-[40%]') + ' relative mx-auto mb-8'">
                <img class="h-full mx-auto my-auto cursor-pointer" src="../assets/birdBackground.png">
                <img :class="(landscape ? 'w-[22%]' : 'w-[50%]') + ' absolute bottom-0 left-1/2 -translate-x-bird-start'" src="../assets/bird.png">
            </div>
            <div>
                <div class="mx-auto pb-6">
                    <p v-if="landscape" class="font-heading text-white  text-center text-birdTitle">{{$t('sound.startDescription')}}</p>
                    <p v-else class="font-heading text-white  text-center text-birdTitlePortrait">{{$t('sound.startDescription')}}</p>
                </div>
                <div :class="(landscape ? 'landscape' : 'portrait') + ' flex justify-center'">
                    <router-link :to="{ name: 'BirdSoundTutorial' }" class="router-link">
                        <Button :text="$t('scratch.startGame')" buttonType="primary" @click="startTutorial" />
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

import Button from "@/components/Button.vue";
import Language from '@/components/Language';

export default {
	name: 'BirdSoundTutorial',
	components: {
        Button,
        Language
	},
	data(){
        return {
            help: false,
            landscape: false,
            seconds: 0,
            standard_sec: 60,
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',

        }
    },
	mounted() {
        this.myEventHandler();
         const query_seconds = this.$route.query.seconds;
         if (query_seconds) {
            if (query_seconds == 0) {
                this.seconds = 0;
            } else {
                this.startTimer();
            }
        } else {
            this.seconds = 0;
        }
    },
	created() {
        window.addEventListener("resize", this.myEventHandler);
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener("resize", this.myEventHandler);
        window.removeEventListener('scroll', this.handleScroll);
    },
	methods: {
        startTutorial() {
            this.help = true;
        },
        src() {
            return require("../assets/logo_dark.png")
        },
        myEventHandler() {
            if (screen.width > screen.height) {
                this.landscape = true;
            } else {
                this.landscape = false;
            }
        },
                startTimer() {
            this.$router.replace({ query: {seconds: "60", category: this.$route.query.category} })
            this.seconds = this.standard_sec;
            setTimeout(this.countdown, 1000);
        },
        countdown() {
            // console.log(this.seconds);
            if (this.seconds > 0) {
                this.seconds -= 1;
                setTimeout(this.countdown, 1000);
            }
        },
        click() {
            if (this.seconds > 0) {
                this.seconds = this.standard_sec;
            }
        },
        handleScroll (event) {
            if (this.seconds > 0) {
                this.seconds = this.standard_sec;
            }
        },
    },
}
</script>

<style>
    .landscape .router-link div button {
        width: 15vw;
        height: 5vh;
        font-size: 1vw;
        padding-left: 3em;
        padding-right: 3em;
    }

    .portrait .router-link div button {
        font-size: 2vw;
        padding-left: 3em;
        padding-right: 3em;
    }

</style>
